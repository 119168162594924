body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Quicksand";
  font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #adadad;
  /* color of the tracking area */
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background-color: gainsboro;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: none;
  /* creates padding around scroll thumb */
}
.styles_react-code-input__CRulA {
  display: flex;
  gap: 10px;
  justify-content:center;
}
.styles_react-code-input__CRulA > input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  width: 40px !important;
  height: 54px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;
